<template>
    <div style="min-height:200px">
        <b-card>
            <app-view-table :title="trans('sent-emails', 207)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :filterOptions="status_options" :isFilterOptions="true" :isSearch="true" :isDropdownMenu="false">

            </app-view-table>
        </b-card>  

    </div>
</template>
<script>

import axios from "@axios";

export default {

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                page: 1,
                per_page: 10,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                 filtering: {                        
                        types: {type: 'list', changed: 0, values: [], function: "getQueueType", parameters: null, widthClass:'app-width-min-250'},
                        dates: { type: 'dates', changed: 0, values: [],  function: "", parameters: {type: null}, widthClass:'app-width-min-350'},      
                        ids: {type: 'list', changed: 0, values: [], function: "getEmailsSentId", parameters: null, widthClass:'app-width-min-250'},
                        emails: {type: 'list', changed: 0, values: [], function: "getEmailsSentEmails", parameters: null, widthClass:'app-width-min-250'},             
                },
                search: "",
                list_type:1,
                status: 1,
                changed: 0,
            },

            status_options: [
                { value: 1, name: this.trans('settings-all',167) },
                { value: 2, name: this.trans('person',209) },
                { value: 3, name: this.trans('client',5)  }
            ],
            fields: [
                { key: "event_date", label: this.trans('settings-event-date',207), thStyle: { "min-width": "170px", "max-width": "170px"}, visible: true, filtering:true, filtering_name: "dates" },
                { key: "name_type", label: this.trans('type',182), thStyle: { width: "100%" }, visible: true, filtering:true, filtering_name: "types" },
                { key: "email", label: this.trans('email',182), thStyle: { "min-width": "290px", "max-width": "290px" }, visible: true, filtering:true, filtering_name: "emails" },
                { key: "id", label: this.trans('settings-id',207), thStyle: { "min-width": "100px", "max-width": "100px"}, visible: true, filtering:true, filtering_name: "ids" },                
            ],
            items: [],
        };
    },

    methods: {
        getList: function() {
            this.loading_list= true,
            axios
                .post("settings/emails-sent/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error : ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.dates.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.ids.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.filtering.emails.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

        },
    },
 
};
</script>
<style scoped>
    .app-local-pre{
        white-space: pre-wrap;
    }
</style>